import * as React from 'react';
import { Component, ReactNode } from 'react';
import BaseGame from './forms/games/BaseGame';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as GamesStore from '../store/games/Games';
import * as MyGamesStore from '../store/MyGames';
import * as AccountStore from '../store/Account';
import { Loading } from './Loading';
import { MeState } from '../store/Me';
import { AppState } from '../store/configureStore';
import {DictGetValues} from "../services/JsDict";
import {PlayListState, actionCreators as playlistActions} from "../store/PlayListStore";
import * as OrgStore from '../store/OrganizationStore';
import * as GlobalStore from '../store/GlobalComponentStore';
import {GamesState} from "../store/games/Games";
import {GameType} from "../model/Game/GameType";
import TopMenuButton from "./Menu/TopMenuButton";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import { match } from 'react-router-dom';

interface Props{
    setLayoutButtons: (n: ReactNode) => void;
    gameState: GamesStore.AbstractGameState;
    gamesState: GamesState;
    newGameElementFunction?: () => void;
    newGameElementDisabled?: boolean;
    meState: MeState;
    myGamesActions: typeof MyGamesStore.actionCreators;
    myGamesState: MyGamesStore.MyGamesState;
    gamesActions: typeof GamesStore.actionCreators;
    accountActions: typeof AccountStore.actionCreators;
    orgActions: typeof OrgStore.actionCreators;
    account: AccountStore.AccountState;
    children: ReactNode;
    contentClass?: string;
    playlist: PlayListState;
    playListActions: typeof playlistActions;
    newGameElementText?: string;
    gameType: GameType;
    extraButtons?: Array<{id: string; hoverMsg: string; icon: IconProp; action: () => void}|null>;
    routeMatch: match<{id?: string}>;
    globalActions: typeof GlobalStore.actionCreators;
}
interface State {
    showTransferOwner: boolean;
    playlistAddTranslationId?: string;
}

class EditGameComponentSimple extends Component<Props, State>{

    constructor(props: Props){
        super(props);
        this.state = {
            showTransferOwner: false
        };

        const myAccount = this.props.meState.account;
        const meId      = myAccount && myAccount.id;
        if(!this.props.playlist.loading.getPlayLists && meId && DictGetValues(this.props.playlist.playListsMap).length === 0){
            this.props.playListActions.getPlayLists();
        }

        const idFromRouting = props.routeMatch.params.id;
        if (idFromRouting){
            props.gamesActions.getGame(idFromRouting, true, props.gameType);
            props.gamesActions.getStats(props.gameType, idFromRouting);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        const newGame = this.props.gameState.game;
        const oldGame = prevProps.gameState.game;
        const oldRouteId = prevProps.routeMatch.params.id;
        const newRouteId = this.props.routeMatch.params.id;
        const oldAcc = prevProps.meState.account;
        const newAcc = this.props.meState.account;

        if(newGame){
            if(!oldGame || oldGame.ownerId !== newGame.ownerId){
                prevProps.accountActions.get(newGame.ownerId, false);
            }
        }

        if(oldRouteId !== newRouteId){
            if (newRouteId){
                this.props.gamesActions.getGame(
                    newRouteId,
                    true,
                    prevProps.gameType
                );
                this.props.gamesActions.getStats(this.props.gameType, newRouteId);
            }
        }

        if(JSON.stringify(newGame) !== JSON.stringify(oldGame) || JSON.stringify(newAcc) !== JSON.stringify(oldAcc)){
            this.props.setLayoutButtons(this.renderButtons());
        }
    }

    componentDidMount(){
        this.props.setLayoutButtons(this.renderButtons());
    }

    componentWillUnmount() {
        this.props.setLayoutButtons(undefined);
    }

    renderButtons = () => {
        const props = this.props;
        const game = props.gameState.game;

        return(
            <>
                <TopMenuButton
                    action={() => props.globalActions.playlistAddItemForm(game)}
                    icon={'plus-square'}
                    hoverMsg={'playlist_add_to'}
                    disabled={!game?.isPublished}
                />
                <TopMenuButton
                    action={() => props.globalActions.bundleAddItemForm(game)}
                    icon={'plus-circle'}
                    hoverMsg={'bundle_add_to'}
                    disabled={!game?.isPublished}
                />
                <TopMenuButton
                    action={() => props.globalActions.wallAddItemForm(game)}
                    icon={'folder-plus'}
                    hoverMsg={'wall_add_to'}
                    disabled={!game?.isPublished}
                />
                {props.extraButtons && props.extraButtons.map(x => x === null ? null : <TopMenuButton key={x.id} {...x} />)}
            </>
        );
    };

    render(){
        const props = this.props;

        return(
            <div className={`layout-content ${props.contentClass || ''}`}>
                <Loading visible={props.gameState.isLoading.getGame || props.myGamesState.isLoading.deleteGame} />
                {props.children}
                <BaseGame />
            </div>
        )
    }
}

export default connect(
    (state: AppState) => ({
        meState: state.me,
        account: state.account,
        playlist: state.playlist,
        gamesState: state.gamesState,
        myGamesState: state.mygames
    }),
    dispatch => ({
        myGamesActions: bindActionCreators(MyGamesStore.actionCreators, dispatch),
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch),
        accountActions: bindActionCreators(AccountStore.actionCreators, dispatch),
        playListActions: bindActionCreators(playlistActions, dispatch),
        orgActions: bindActionCreators(OrgStore.actionCreators, dispatch),
        globalActions: bindActionCreators(GlobalStore.actionCreators, dispatch)
    })
)(EditGameComponentSimple);
