import axios from "axios";
import { CreateAuthHeader } from "../../services/AxiosHelper";
import { errorActions } from "../Error";
import { actionCreators as accountActions} from "../Account";
import { BaseGame } from "../../model/Game/BaseGame";
import {GameClass, GameType, GameTypesMyWall} from "../../model/Game/GameType";
import {TA} from "../configureStore";
import {Reducer} from "redux";
import {history} from "../../index";
import GoogleTranslateList from "../../model/GoogleTranslateList";
import {GameStats} from "../../model/GameStats";
import {defaultLanguageList} from "../../model/DefaultLanguages";
import { ImageUploadRequest } from "../../model/Request/ImageUploadRequest";

const GAMES_SHOW_CONFIRM_DELETE = 'GAMES_SHOW_CONFIRM_DELETE';
const GAMES_SET_GOOGLE_TRANSLATE = 'GAMES_SET_GOOGLE_TRANSLATE';
const GAMES_SET_CAN_GOOGLE_TRANSLATE = 'GAMES_SET_CAN_GOOGLE_TRANSLATE';
const GAMES_GET_STATS_RESPONSE = 'GAMES_GET_STATS_RESPONSE';
const GAMES_GET_STATS_REQUEST = 'GAMES_GET_STATS_REQUEST';
const GAMES_GET_BYFLOOR_REQUEST = 'GAMES_GET_BYFLOOR_REQUEST';
const GAMES_GET_BYFLOOR_RESPONSE = 'GAMES_GET_BYFLOOR_RESPONSE';

export const reducerName = 'gamesState';

export const isTempGame = (game: TempGame | GameClass): game is TempGame => {
    return "loading" in game;
};

export interface TempGame{
    id: string;
    loading: true;
}

export interface AbstractGameState {
    game?: BaseGame;
    gameStats?: GameStats;
    gameType: GameType;
    ui: AbstractGameUi;
    isLoading: AbstractGameIsLoading;
}

export interface AbstractGameIsLoading {
    readonly getGame?: boolean;
    readonly updateSettings?: boolean;
    readonly transferOwner?: boolean;
    readonly addAuthor?: boolean;
    readonly removeAuthor?: boolean;
}

export interface AbstractGameUi {
    readonly showSettings?: boolean;
}

export interface LanguageCode {
    name: string;
    value: string;
}

export interface GamesState {
    readonly games: {[key: string]: GameClass | TempGame};
    readonly gamesByOrg?: BaseGame[];
    readonly languageCodes: LanguageCode[];
    readonly activeGameTypes?: Array<GameType>;
    readonly createCopy: {
        readonly langCode: string;
        readonly googleTranslate: boolean;
        readonly canTranslate: boolean;
    };
    readonly loading: {
        readonly getGamesByAccount: boolean;
        readonly addAuthor: boolean;
        readonly removeAuthor: boolean;
        readonly createCopy?: boolean;
        readonly getGamesByFloor?: boolean;
        readonly getGamesByOrg?: boolean;
    };
    readonly ui: {
        readonly showAuthorForm: boolean;
        readonly showCreateCopy?: boolean;
        readonly confirmDelete?: string;
    };
}

const initialState: GamesState = {
    games: {},
    languageCodes: defaultLanguageList,
    activeGameTypes: [...GameTypesMyWall],
    createCopy:{
        langCode: '',
        googleTranslate: true,
        canTranslate: false
    },
    loading: {
        getGamesByAccount: false,
        addAuthor: false,
        removeAuthor: false,
    },
    ui : {
        showAuthorForm: false
    }
};



export const actionCreators = {
    showAuthorForm: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'GAMES_SHOW_AUTHOR_FORM', show});
    },
    transferOwner: (game: BaseGame, newOwnerId: string): TA => async(dispatch, getState) => {
        dispatch({type: 'GAMES_TRANSFER_OWNER_REQUEST', gameId: game.id});
        const gameId = game.id;
        const gameType = game.gameType;
        const url = `api/${gameType}/${gameId}/transferOwner/${newOwnerId}`;

        axios.post(url, null, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type:'GAMES_TRANSFER_OWNER_RESPONSE', data:response.data});
                dispatch(accountActions.get(newOwnerId, false));
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'transferOwner', error));
            });

    },
    getStats: (gametype: GameType, gameId: string): TA => async(dispatch, getState) => {
        dispatch({type: GAMES_GET_STATS_REQUEST, gameType: gametype});
        axios.get(`api/game/${gameId}/stats`, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: GAMES_GET_STATS_RESPONSE, stats: response.data, gameType: gametype})
            });
    },
    addAuthor: (gameType: GameType, gameId: string, authorId: string): TA => async(dispatch, getState) => {
        dispatch({type: 'GAMES_ADD_AUTHOR_REQUEST', gameId: gameId});
        const url = `api/${gameType}/${gameId}/author/${authorId}`;
        axios.post(url, null, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: 'GAMES_ADD_AUTHOR_RESPONSE', data });
                dispatch(accountActions.get(authorId, false));
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'addAuthor', error));
            });
    },
    removeAuthor: (gameType: GameType, gameId: string, authorId: string): TA => async(dispatch, getState) => {
        dispatch({type: 'GAMES_REMOVE_AUTHOR_REQUEST', gameId: gameId});
        const url = `api/${gameType}/${gameId}/author/${authorId}`;
        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: 'GAMES_REMOVE_AUTHOR_RESPONSE', data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'removeAuthor', error));
            })
    },
    getLanguageCodes: (): TA => async(dispatch, getState) => {
        axios.get("api/languagecodes")
            .then(response => {
                dispatch({type: 'GAMES_LANGUAGE_CODES_RESPONSE', data: response.data});
            })
    },
    setCopyLanguageCode: (code: string): TA => async(dispatch, getState) => {
        dispatch({type: 'GAMES_COPY_SET_CODE', code});
        if(!GoogleTranslateList.find(gl => gl === code)){
            dispatch({type: GAMES_SET_GOOGLE_TRANSLATE, shouldTranslate: false});
            dispatch({type: GAMES_SET_CAN_GOOGLE_TRANSLATE, canTranslate: false});
        }
        else{
            dispatch({type: GAMES_SET_CAN_GOOGLE_TRANSLATE, canTranslate: true});
            dispatch({type: GAMES_SET_GOOGLE_TRANSLATE, shouldTranslate: true});
        }
    },
    setGoogleTranslate: (shouldTranslate: boolean): TA => async(dispatch, getState) => {
        dispatch({type: GAMES_SET_GOOGLE_TRANSLATE, shouldTranslate});
    },
    setActiveGame: (gameId: string, gameType: GameType): TA => async(dispatch, getState) => {
        dispatch(actionCreators.getGame(gameId, true, gameType));
        history.push(`/workshop/${gameType}/edit/${gameId}`);
    },
    getGame: (gameId: string, forceRefresh: boolean, gameType?: GameType): TA => async(dispatch, getState) => {
        const hasAlready = getState().gamesState.games[gameId];
        if(hasAlready && !forceRefresh) return;
        const tempGame = hasAlready || {id: gameId, loading: true};
        dispatch({ type: 'GAMES_GAME_REQUEST', tempGame, gameType });

        const url = gameType ? `api/${gameType}/${gameId}` : `api/game/${gameId}`;
        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({ type: 'GAMES_GAME_RESPONSE', data: response.data, gameType});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'getGame', error));
            });
    },
    updateSettings: (gameType: GameType, gameId: string, settings: Record<string, string|number|boolean|ImageUploadRequest|undefined>): TA => async(dispatch, getState) => {
        dispatch({type: 'GAMES_UPDATE_SETTINGS_REQUEST', gameType});
        dispatch(errorActions.resetAllErrors(reducerName));
        const url = `api/workshop/${gameType}/${gameId}/settings`;
        axios.put(url, settings, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: 'GAMES_UPDATE_SETTINGS_RESPONSE', data, gameType});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'updateSettings', error));
            })
    },
    showSettings: (gameType: GameType, show: boolean): TA => async(dispatch) => {
        dispatch({type: 'GAMES_SHOW_SETTINGS', show, gameType});
    },
    showCreateCopy: (gameType: GameType, show: boolean): TA => async (dispatch, getState) => {
        dispatch({type: 'GAMES_SHOW_CREATE_COPY', show, gameType});
    },
    showConfirmDelete: (gameId: string|undefined): TA => async(dispatch, getState) => {
        dispatch({ type: GAMES_SHOW_CONFIRM_DELETE, gameId});
    },
    createCopy: (gameId: string, gameType: GameType, langCode: string, useGoogle: boolean): TA => async(dispatch, getState) => {
        const meAccount = getState()['me'].account;
        if(!meAccount) return;
        dispatch({ type: 'GAMES_COPY_CREATE_REQUEST', gameType});

        const data = {
            "LanguageCode" : langCode,
            "useGoogle" : useGoogle
        };
        const url = `api/${gameType}/${gameId}/copy`;

        axios.post(url, data, CreateAuthHeader(getState))
            .then(response => {
                history.push(`/workshop/${gameType}/edit/${response.data.id}`);
                dispatch({ type: 'GAMES_COPY_CREATE_RESPONSE', data: response.data });
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'createCopy', error));
            })
    },
    getGamesByFloor: (apiKey: string): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetError(reducerName, 'getGamesByFloor'));
        dispatch({type: GAMES_GET_BYFLOOR_REQUEST});

        const url = 'api/game/byfloor';
        axios.post(url, {id: apiKey}, CreateAuthHeader(getState))
            .then(response => dispatch({type: GAMES_GET_BYFLOOR_RESPONSE, data: response.data}))
            .catch(error => dispatch(errorActions.reportAxiosError(reducerName, 'getGamesByFloor', error)))
    },
    getGamesByOrg: (orgId: string): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetError(reducerName, 'getGamesByOrg'));
        dispatch({type: "GAMES_GET_BY_ORG_REQUEST"});

        const filters = getState().libraryState.gameFilters;
        const data = {
            gameFilters: filters,
            sort: filters.sort.key,
            ascending: filters.sort.ascending
        };

        axios.post(`api/game/organization/${orgId}/9999`, data, CreateAuthHeader(getState))
            .then(r => dispatch({type: "GAMES_GET_BY_ORG_RESPONSE", data: r.data}))
            .catch(e => dispatch(errorActions.reportAxiosError(reducerName, 'getGamesByOrg', e)));
    }
};

// eslint-disable-next-line
export const abstractGameReducerMethods: {[type: string]: (state: AbstractGameState, action: any) => AbstractGameState} = {
    GAMES_GET_STATS_RESPONSE: (state, action) => {
        if(state.gameType !== action.gameType) return state;
        return{
            ...state,
            gameStats: action.stats
        }
    },
    GAMES_GET_STATS_REQUEST: (state, action) => {
        if(state.gameType !== action.gameType) return state;
        return{
            ...state,
            gameStats: undefined
        }
    },
    GAMES_GAME_REQUEST: (state, action) => {
        if(state.gameType !== action.gameType) return state;
        return{
            ...state,
            isLoading: {
                ...state.isLoading,
                getGame: true
            }
        }
    },
    GAMES_GAME_RESPONSE: (state, action) => {
        if (state.gameType !== action.gameType) return state;
        return{
            ...state,
            game: action.data,
            isLoading: {
                ...state.isLoading,
                getGame: false
            }
        }
    },
    GAMES_SHOW_SETTINGS: (state, action) => {
        if(state.gameType !== action.gameType) return state;
        return{
            ...state,
            ui:{
                ...state.ui,
                showSettings: action.show
            }
        }
    },
    GAMES_UPDATE_SETTINGS_REQUEST: (state, action) => {
        if(state.gameType !== action.gameType) return state;
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                updateSettings: true
            }
        }
    },
    GAMES_UPDATE_SETTINGS_RESPONSE: (state, action) => {
        if(state.gameType !== action.gameType) return state;
        return{
            ...state,
            game: action.data,
            isLoading:{
                ...state.isLoading,
                updateSettings: false
            },
            ui: {
                ...state.ui,
                showSettings: false
            }
        }
    },
    GAMES_TRANSFER_OWNER_REQUEST: (state, action) => {
        if(!state.game || action.gameId !== state.game.id) return state;
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                transferOwner: true
            }
        }
    },
    GAMES_TRANSFER_OWNER_RESPONSE: (state, action) => {
        if (!state.game || action.data.id !== state.game.id) return state;
        return{
            ...state,
            game: action.data,
            isLoading:{
                ...state.isLoading,
                transferOwner: false
            }
        }
    },
    GAMES_ADD_AUTHOR_RESPONSE: (state, action) => {
        if (!state.game || action.data.id !== state.game.id) return state;
        return{
            ...state,
            game: action.data,
            isLoading:{
                ...state.isLoading,
                addAuthor: false
            }
        }
    },
    GAMES_REMOVE_AUTHOR_RESPONSE: (state, action) => {
        if (!state.game || action.data.id !== state.game.id) return state;
        return{
            ...state,
            game: action.data,
            isLoading:{
                ...state.isLoading,
                removeAuthor: false
            }
        }
    },
    MYGAMES_PUT_GAME_RESPONSE: (state, action) => {
        if (!state.game || action.data.id !== state.game.id) return state;
        return{
            ...state,
            game: action.data,
        }
    },
    GAMES_ADD_AUTHOR_REQUEST: (state, action) => {
        if(!state.game || action.gameId !== state.game.id) return state;
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                addAuthor: true
            }
        }
    },
    GAMES_REMOVE_AUTHOR_REQUEST: (state, action) => {
        if (!state.game || action.gameId !== state.game.id) return state;
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                removeAuthor: true
            }
        }
    }
};

// eslint-disable-next-line
const reducerMethods: { [type: string]: (state: GamesState, action: any) => GamesState } = {
    GAMES_GET_BY_ORG_REQUEST: (state) => {
        return{
            ...state,
            gamesByOrg: undefined,
            loading: {
                ...state.loading,
                getGamesByOrg: true
            }
        }
    },
    GAMES_GET_BY_ORG_RESPONSE: (state, action: {data: {games: BaseGame[]; name: string; resultCount: number}}) => {
        return{
            ...state,
            gamesByOrg: action.data.games,
            loading: {
                ...state.loading,
                getGamesByOrg: false
            }
        }
    },
    GAMES_GET_BYFLOOR_REQUEST : (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                getGamesByFloor: true
            }
        }
    },
    GAMES_GET_BYFLOOR_RESPONSE: (state, action) => {
        const gamesDict: {[key: string]: BaseGame} = action.data;
        return{
            ...state,
            games:{
                ...state.games,
                ...gamesDict
            },
            loading:{
                ...state.loading,
                getGamesByFloor: undefined,
            }
        }
    },
    GAMES_SET_CAN_GOOGLE_TRANSLATE: (state, action) => {
        return{
            ...state,
            createCopy: {
                ...state.createCopy,
                canTranslate: action.canTranslate
            },
        }
    },
    GAMES_SHOW_CREATE_COPY: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showCreateCopy: action.show
            }
        }
    },
    GAMES_SET_GOOGLE_TRANSLATE: (state, action) => {
        return{
            ...state,
            createCopy: {
                ...state.createCopy,
                googleTranslate: action.shouldTranslate
            },
        }
    },
    GAMES_SHOW_CONFIRM_DELETE: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                confirmDelete: action.gameId
            }
        }
    },
    GAMES_COPY_CREATE_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                createCopy: true
            }
        }
    },
    GAMES_COPY_CREATE_RESPONSE: (state, action) => {
        return{
            ...state,
            games:{
                ...state.games,
                [action.data.id]: action.data
            },
            ui:{
                ...state.ui,
                showCreateCopy: false
            },
            loading:{
                ...state.loading,
                createCopy: false,
            }
        }
    },
    GAMES_COPY_SET_CODE: (state, action) => {
        return{
            ...state,
            createCopy: {
                ...state.createCopy,
                langCode: action.code
            }
        }
    },
    GAMES_LANGUAGE_CODES_RESPONSE: (state, action) => {
        return{
            ...state,
            languageCodes: action.data
        }
    },
    GAMES_SHOW_AUTHOR_FORM: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showAuthorForm: action.show
            }
        }
    },
    GAMES_ADD_AUTHOR_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                addAuthor: true
            }
        }
    },
    GAMES_ADD_AUTHOR_RESPONSE: (state, action) => {
        return{
            ...state,
            games:{
                ...state.games,
                [action.data.id]: action.data
            },
            loading:{
                ...state.loading,
                addAuthor: false
            }
        }
    },
    MYGAMES_PUT_GAME_RESPONSE: (state, action) => {
        return{
            ...state,
            games:{
                ...state.games,
                [action.data.id]: action.data
            }
        }
    },
    GAMES_REMOVE_AUTHOR_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                removeAuthor: true
            }
        }
    },
    GAMES_REMOVE_AUTHOR_RESPONSE: (state, action) => {
        return{
            ...state,
            games:{
                ...state.games,
                [action.data.id]: action.data
            },
            loading:{
                ...state.loading,
                removeAuthor: false
            }
        }
    },
    MYGAMES_RECEIVED_MY_GAMES: (state, action) => {
        const gameMap = action.data.reduce(function (acc: { [key: string]: BaseGame }, cur: BaseGame) {
            acc[cur.id] = cur;
            return acc;
        }, {});
        return {
            ...state,
            games: Object.assign(state.games, gameMap)
        };
    },
    LIBRARY_GET_LIST_RESPONSE: (state, action) => {
        const gameMap = action.data.games.reduce(function (acc: { [key: string]: BaseGame }, cur: BaseGame){
            acc[cur.id] = cur;
            return acc;
        }, {});
        return {
            ...state,
            games:Object.assign(state.games, gameMap)
        };
    },
    MYGAMES_DELETE_GAME_RESPONSE: (state, action) => {
        return{
            ...state,
            games:{
                ...state.games,
                [action.data.id]: null
            }
        }
    },
    GAMES_GAME_REQUEST: (state, action) => {
        return{
            ...state,
            games:{
                ...state.games,
                [action.tempGame.id]: action.tempGame
            }
        }
    },
    GAMES_GAME_RESPONSE: (state, action) => {
        return{
            ...state,
            games:{
                ...state.games,
                [action.data.id]: action.data
            }
        }
    },
    ERROR: (state, action) => {
        if (action.reducer !== reducerName) return state;
        return {
            ...state,
            loading: {
                ...state.loading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<GamesState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};