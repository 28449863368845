import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as GamesStore from "../../../store/games/Games";
import Dialog from "../../Dialog";
import {Translate} from "react-localize-redux";
import {SelectList, SubmitButton} from "../../forms/FormGroup";
import {AppState} from "../../../store/configureStore";
import * as GoalShowStore from "../../../store/games/GoalShowStore";

interface Props {
    gamesActions: typeof GamesStore.actionCreators;
    goalShowState: GoalShowStore.GoalShowState;
    goalShowActions: typeof GoalShowStore.actionCreators;
}

const GoalShowSettings = (props: Props) => {
    if (!props.goalShowState.ui.showSettings) {
        return null;
    }

    const game = props.goalShowState.game;
    if (!game) {
        return null;
    }

    const settings = {
        skin: game.skin || 'Default',
    };

    const skinOptions = [
        {name: "Default",    value: "Default"   },
    ];

    return(
      <Dialog
          className='settings'
          onClose={() => props.gamesActions.showSettings(game.gameType, false)}
          loading={props.goalShowState.isLoading.updateSettings}>
          <h2><Translate id='settings'/></h2>
          <form onSubmit={e => e.preventDefault()}>
          <SelectList name='skin' defaultValue={settings.skin} options={skinOptions} onChange={props.goalShowActions.setField}/>
              <h3><Translate id='preview_skin'/></h3>
              <img src={`/images/skins/${game.gameType}/${settings.skin}.png`} alt='' className='skin-preview' />
              <div className='clear-fix' />
              <SubmitButton
                  text='submit'
                  onClick={() => props.gamesActions.updateSettings(game.gameType, game.id, settings)}
                  split
              />
          </form>
      </Dialog>
    );
};

export default connect(
    (state: AppState) => ({goalShowState: state.goalShowState}),
    dispatch => ({
        goalShowActions: bindActionCreators(GoalShowStore.actionCreators, dispatch),
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch)
    })
)(GoalShowSettings);