export function getLocaleStorageItem<T>(key: string, std: T): T {
    const raw = localStorage.getItem(key);
    if(raw){
        return JSON.parse(raw);
    }
    return std;
}

export function saveLocaleStorageItem<T>(key: string, item: T, keysToRemove?: string[]): T{

    const replacer = (key: string, value: unknown) => {
        if(keysToRemove && keysToRemove.indexOf(key) !== -1) return undefined;
        return value;
    }
    const str = JSON.stringify(item, replacer);
    localStorage.setItem(key, str);
    return JSON.parse(str);
}