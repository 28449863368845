import React from 'react';
import { Component } from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {actionCreators as authActions} from './store/Auth';
import moment from 'moment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import { AppState } from './store/configureStore';
import 'moment/locale/da';
import 'moment/locale/en-gb';
import {actionCreators as GamesActions} from "./store/games/Games";
import './mobile.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import MyWallApp from "./components/_MyWall/MyWallApp";
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import * as GlobalStore from './store/GlobalComponentStore';
import da from 'date-fns/locale/da';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import iw from 'date-fns/locale/he';
import nl from 'date-fns/locale/nl';
import no from 'date-fns/locale/nn';
import sv from 'date-fns/locale/sv';
import fr from 'date-fns/locale/fr';
import lt from 'date-fns/locale/lt';


library.add(fab, fas, far);
registerLocale('da', da);
registerLocale('en', en);
registerLocale('es', es);
registerLocale('iw', iw);
registerLocale('nl', nl);
registerLocale('no', no);
registerLocale('sv', sv);
registerLocale('fr', fr);
registerLocale('lt', lt);
setDefaultLocale('da');

interface Props extends RouteComponentProps{
    state: AppState;
    authActions: typeof authActions;
    gamesActions: typeof GamesActions;
    globalActions: typeof GlobalStore.actionCreators;
}

interface StateType {
    showChangelog: boolean;
}

export const GlobalActions = React.createContext(GlobalStore.actionCreators);

class App extends Component<Props, StateType>{

    constructor(props: Props){
        super(props);
        moment.locale("da");

        props.gamesActions.getLanguageCodes();

        setInterval(() => {
            //refresh token, if its 2mins from expiring
            const now = moment();
            const expireString = this.props.state.auth.expiretime || '';
            const expiretime = moment(expireString).add(-2, "minutes");
            if(expiretime.isBefore(now)){
                if (!this.props.state.errors.auth.refreshToken){
                    this.props.authActions.refreshToken();
                }
            }
        }, 30000);//run every 30sec
    
        const expireTimeString = localStorage.getItem("myfloorjwt_expire") || '';
        const expireTime = moment(expireTimeString);
        let token;
        if(expireTime && expireTime > moment()){
            token = localStorage.getItem("myfloorjwt") || '';
            this.props.authActions.setTokenFromStorage(token, expireTime.format());
        }

        this.state = {
            showChangelog: false
        };
    }

    render(){
        return(
            <GlobalActions.Provider value={this.props.globalActions}>
                <MyWallApp
                    isShowingLog={this.state.showChangelog}
                    showLog={() => this.setState({ showChangelog: true })}
                    hideLog={() => this.setState({ showChangelog: false })}
                />
            </GlobalActions.Provider>
        );
    }
}

export default withRouter(
    connect(
        (state: AppState) => ({state: state}),
        (dispatch) => ({
            authActions : bindActionCreators(authActions, dispatch),
            gamesActions: bindActionCreators(GamesActions, dispatch),
            globalActions: bindActionCreators(GlobalStore.actionCreators, dispatch)
        })
    )(App)
);
