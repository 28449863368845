import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AccountType, getFullName } from '../../model/AccountType';
import { PlayListType } from '../../model/PlayListType';
import { FetchError, fetchGet } from '../../services/FetchHelper';
import { Button } from '../forms/FormGroup';
import { Loading } from '../Loading';
import PlayListPreview from '../ModelPreview/PlayListPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuImg from '../../images/floor_menu.png';
import { GeneralError } from '../Error/GeneralError';

interface Props extends RouteComponentProps<{aId?: string; pId?: string}>{
    allreadyAdded?: boolean;
    error?: boolean;
}

const AddPlaylistSuccess = (props: Props) => {
    const pId = props.match.params.pId;
    const aId = props.match.params.aId;

    const [account, setAccount] = useState<AccountType>();
    const [playlist, setPlaylist] = useState<PlayListType>();
    const [error, setError] = useState<FetchError>();

    useEffect(() => {
        if(pId){
            fetchGet<PlayListType>(`api/workshop/playlist/${pId}`)
            .then(setPlaylist)
            .catch(setError);
        }
    }, [pId]);

    useEffect(() => {
        if(aId){
            fetchGet<AccountType>(`api/account/${aId}`)
            .then(setAccount)
            .catch(setError);
        }
    }, [aId]);

    if(props.error){
        return <div className='landing'><Translate id={'unknown_error'}/></div>
    }

    if(error) return <GeneralError fetchError={error} />;

    return(
        <div className='landing-background'>
            <div className='landing'>
                <Loading visible={!account || !playlist}/>
                <div className='landing-content'>
                    <div className='split left'>
                        {account &&  <h1><FontAwesomeIcon icon='check' /><Translate id='playlist_add_landing_h1' data={{name: getFullName(account)}}/></h1> }
                        {playlist && <h3><Translate id={props.allreadyAdded ? 'playlist_add_landing_alt_h3' : 'playlist_add_landing_h3'} data={{name: playlist?.name}} /></h3>}
                        <div className='more-info'>
                            <h2><Translate id='playlist_add_landing_myfloor' /></h2>
                            <p><Translate id='playlist_add_landing_myfloor_p' /></p>
                            <Button icon='angle-right' name='Login' link={'/login'} />
                        </div>
                    </div>
                    <div className='split'>
                        <div className='right'>
                            <div className='menu-img-container'>
                                <img className='menu-img' src={MenuImg} alt='menu-img' />
                                {playlist && 
                                    <div className='playlist-wrapper'>
                                        <PlayListPreview playlist={playlist} onlyImage noMenu />
                                        <div className='plus-icon'>
                                            <FontAwesomeIcon icon="plus" />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddPlaylistSuccess;