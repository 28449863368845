import React, {Component, ReactNode, MouseEvent, CSSProperties} from "react";
import missingImage from "../../images/games/missingimage220x150.png";
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators as gamesActions, isTempGame} from "../../store/games/Games";
import * as GameStore from "../../store/games/Games";
import * as MyGamesStore from '../../store/MyGames';
import * as PlaylistStore from '../../store/PlayListStore';
import * as MeStore from '../../store/Me';
import * as OrgStore from '../../store/OrganizationStore';
import {DbOrUploadImage} from "../../services/ImageHelper";
import throttle from "lodash.throttle";
import {convertHexToRgba} from "../../services/ColorHelper";
import {AppState} from "../../store/configureStore";
import {BaseGame} from "../../model/Game/BaseGame";
import {GameTagList} from "../WorkShop/GameTagList";
import GameMenuDefault from "./GameMenuDefault";
import moment from "moment";

interface Props{
    game?: BaseGame;
    gameId?: string;
    count?: number;
    onClick?: (gameId: string) => void;
    onlyImage?: boolean;
    noPadding?: boolean;
    className?: string;
    noMenu?: boolean;
    menuHideEdit?: boolean;
    customMenu?: ReactNode;
    renderCustomMenu?: (g: BaseGame) => ReactNode;
    scale?: number;
    autoScale?: boolean;
    greyed?: boolean;
    style?: CSSProperties;
    gamesActions: typeof GameStore.actionCreators;
    games: GameStore.GamesState;
    meState: MeStore.MeState;
    myGamesActions: typeof MyGamesStore.actionCreators;
    playListActions: typeof PlaylistStore.actionCreators;
    orgActions: typeof OrgStore.actionCreators;
    width?: number;
}

interface State{
    widthFactor: number;
    showMenu: boolean;
}

class Game extends Component<Props, State>{

    constructor(props: Props){
        super(props);
        this.state = {
            widthFactor: props.scale || 1,
            showMenu: false
        }
    }

    componentDidMount(){
        if(!this.props.game && this.props.gameId){
            this.props.gamesActions.getGame(this.props.gameId, false);
        }
        if(this.props.autoScale) {
            this.remeasure();
            window.addEventListener('resize', throttle(this.remeasure, 500));
        }
    }

    componentWillUnmount(){
        if(this.props.autoScale) {
            window.removeEventListener('resize', throttle(this.remeasure, 500));
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State){
        if(prevProps.width !== this.props.width){
            this.remeasure();
        }
    }

    remeasure = () => {
        if (this.props.width){
            const widthFactor = this.props.width / 220;
            this.setState({
                widthFactor: widthFactor,
            });
        }
    };


    render(){
        const props = this.props;
        if(!props.game && !props.gameId) return false;
        const game = props.game || (props.gameId && this.props.games.games[props.gameId]);
        if(!game || isTempGame(game)){
            return false;
        }
        const {widthFactor} = {...this.state};

        const meId = props.meState.account && props.meState.account.id;

        const notEditable : boolean = this.props.game?.gameType === 'SuperSorter' || this.props.game?.gameType === 'Presentation';

        const clickable = !!(!props.noMenu || props.onClick) && !notEditable;
        const onClick = (e: MouseEvent<HTMLElement>) => {
            if(props.onClick && !notEditable){
                e.stopPropagation();
                props.onClick(game.id);
            }
            else if(!props.noMenu){
                this.setState({showMenu: !this.state.showMenu});
            }
        };

        const style = widthFactor ? { ...props.style, width: 220 * widthFactor} : props.style;

        return (
            <div
                className={`game${props.onlyImage ? ' only-image' : ''}${props.className ? ` ${props.className}` : ''}`}
                style={style}
            >
                {game.gameType &&
                <div className={`game-preview${props.noPadding ? ' no-padding': ''}`}>
                    <GameIcon
                        game={game}
                        isAuthor={!!(meId === game.ownerId || (game.authorIds && game.authorIds.find(x => x === meId)))}
                        clickable={clickable}
                        onClick={onClick}
                        menuVisible={this.state.showMenu}
                        noMenu={this.props.noMenu}
                        playlistCount={props.count}
                        scale={widthFactor}
                        customMenu={props.renderCustomMenu ? props.renderCustomMenu(game) : props.customMenu}
                    />
                    { !props.onlyImage &&
                       <GameTagList game={game} />
                    }
                    { !props.onlyImage &&
                        <div className='game-text' onClick={() => this.setState({showMenu: !this.state.showMenu})}>
                            <div className='game-title' dir='auto'>{game.title}</div>
                            <div className='game-description' dir='auto'>{game.description}</div>
                        </div>
                    }
                </div>
                }
            </div>
        );
    }
}

export default connect(
    (state: AppState) => ({
        games: state[GameStore.reducerName],
        meState: state[MeStore.reducerName],
    }),
    dispatch => ({
        gamesActions: bindActionCreators(gamesActions, dispatch),
        myGamesActions: bindActionCreators(MyGamesStore.actionCreators, dispatch),
        playListActions: bindActionCreators(PlaylistStore.actionCreators, dispatch),
        orgActions: bindActionCreators(OrgStore.actionCreators, dispatch)
    })
)(Game);

interface MenuProps{
    game: BaseGame;
    visible: boolean;
    isAuthor: boolean;
    customMenu?: ReactNode;
}

const GameMenu = (props: MenuProps) => {
    return(
        <div className={`icon-menu ${!props.visible && 'hidden'}`}>
            {props.customMenu ? props.customMenu : <GameMenuDefault game={props.game} isAuthor={props.isAuthor} />}
        </div>
    );
};


interface GameIconProps{
    game: BaseGame;
    customMenu?: ReactNode;
    renderCustomMenu?: (g: Game|GameStore.TempGame) => ReactNode;
    isAuthor: boolean;
    menuVisible: boolean;
    clickable: boolean;
    noMenu?: boolean;
    playlistCount?: number;
    scale?: number;
    onClick: (e: MouseEvent<HTMLElement>) => void;
}

const GameIcon = (props: GameIconProps) => {
    const game = props.game;
    const titleStyle = {
        fontSize: `${game.fontSize * (props.scale || 1)}px`,
        fontFamily: game.fontFamily, color: game.fontColorHex
    };
    const topGradColor = convertHexToRgba(game.labelColorHex, 0.867);
    const botGradColor = convertHexToRgba(game.labelColorHex, 0);
    const labelStyle = {
        background: `linear-gradient(to bottom, ${topGradColor}, ${botGradColor}`
    };
    const draft = !game.isPublished;
    const isNews = game.isPublic && moment(game.createdDateTime).isAfter(moment().add(-30, 'days'));
    const isUpdated = !isNews && game.isPublic && moment(game.editedDateTime).isAfter(moment().add(-14, 'days'));
    return(
        <div
            className={`icon-container ${props.clickable ? ' clickable' : ''}`}
            onClick={props.onClick}
            style={{width: 220*(props.scale || 1), height: 150 * (props.scale || 1)}}
        >
            <div className='icon'>
                <div className={draft ? 'draft' : ''} style={{backgroundColor: game.backgroundColorHex || '#FFF'}}>
                    <DbOrUploadImage image={game.image} tempImage={missingImage} className='game-img' realSize/>
                    <img src={`/images/gicon/game_${game.gameType}.png`} alt='' className='gicon'/>
                    {game.showLabel && <div className='game-icon-label' style={labelStyle} /> }
                    {game.showTitle && <div className='game-icon-title' style={titleStyle} dir='auto'>{game.title}</div>}
                    {props.playlistCount !== undefined && <div className='game-pl-count'>{props.playlistCount}</div>}
                </div>

                {draft &&
                <div className='draft-label'>
                    <div className='draft-title'><Translate id='draft'/></div>
                    <div className='draft-text'><Translate id='draft_info'/></div>
                </div>
                }
                {!props.noMenu &&
                <GameMenu game={game} customMenu={props.customMenu} visible={props.menuVisible} isAuthor={props.isAuthor}  />
                }
                {isNews && <div className='new-badge'><Translate id='new_badge'/></div>}
                {isUpdated && <div className='update-badge'><Translate id='update_badge'/></div>}
            </div>
        </div>
    )
};