import React, {CSSProperties} from 'react';
import {TextAlignment, TextType} from "../model/Game/TextType";

export const defaultEmptyText: TextType = { content: "", fontColorHex: "#FFF", fontFamily: "Lato", fontSize: 14, textAlignment: TextAlignment.Left};

interface Props{
    element: string;
    text: TextType|undefined;
    overrideFontSize?: string;
    className?: string;
    style?: CSSProperties;
    scale?: number;
    refObject?: React.RefObject<HTMLDivElement>;
}

const TextView = (props: Props) => {
    const {text, scale: _scale, refObject} = props;
    const scale = _scale || 1;

    const style: CSSProperties = text
        ? {
            ...props.style,
            fontSize: props.overrideFontSize ? props.overrideFontSize : (text.fontSize * scale),
            color: text.fontColorHex,
            fontFamily: text.fontFamily,
            textAlign:
                text.textAlignment === TextAlignment.Left
                ? 'left'
                : text.textAlignment === TextAlignment.Center
                    ? 'center'
                    : "right",
         }
    : {...props.style};
    return React.createElement(props.element, {style, ref: refObject, className: props.className, dir: 'auto'}, text?.content || "");

}

export default TextView;